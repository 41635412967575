import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "../styles/style.css"
import imgPDF from "../images/icon-pdf.png"

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, sans-serif, serif",
  fontSize: 18,
  letterSpacing: "0.03em",
}
const containerStyle = {
  maxWidth: 1280,
  margin: "auto",
  padding: 25,
}

const pdfStyle = {
  display: "flex",
  alignItems: "center",
  marginTop: 36,
}

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <div style={containerStyle}>
        <h1>Michal Ličko</h1>
        <section>
          <div>
            <p><strong>Michal Ličko</strong></p>
            <p>J.A. Komenského 940/36</p>
            <p>038&nbsp;52 Sučany</p>
            <p>Slovenská republika</p>
            <p><a href="mailto:michal.licko@centrum.sk">michal.licko@centrum.sk</a></p>
            <div style={pdfStyle}>
              <img
                src={imgPDF}
                alt="Confirmed"
                style={{maxWidth: '32px', marginRight: '8px'}}
              />
              <a href="/pdf/poriadok.pdf" target="_blank" rel="noopener noreferrer">Prepravný poriadok</a>
            </div>
          </div>
          <div>
            <StaticImage 
              src="../images/auto.png"
              width={600}
              alt="Auto"
            />
          </div>
        </section>
      </div>
    </main>
  )
}

export default IndexPage

export const Head = () => <title>Michal Ličko</title>